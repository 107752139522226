import { useContext } from 'react'
import { ALaCarteGuest } from './ALaCarteGuest'
import { MapVariationForProduct } from './MapVariationForProduct'
import { OrderLineItem } from './OrderLineItem'
import { User } from './User'
import { CreateDownloadUrlResponse } from 'providers/PrivateAssetDownloadProvider'
import AppContext from 'AppContext'

export interface Order {
  publicId: string
  createdAt: Date
  disabled: boolean
  guestEmail?: string
  lineItems: OrderLineItem[]
  paymentMethodLast4: string
  total: number
  user?: User
  visitor: User | ALaCarteGuest
}

export const convertApiRecord = (attrs): Pick<Order, 'createdAt' | 'publicId'> => ({
  createdAt: new Date(attrs.createdAt),
  publicId: attrs.publicId,
})

type CreateDownloadUrlFn = (order: Order, mapVariation: Pick<MapVariationForProduct, 'id'>) => Promise<CreateDownloadUrlResponse>

export const useCreateDownloadUrl = (): CreateDownloadUrlFn => {
  const { restClient } = useContext(AppContext)

  return (order: Order, mapVariation: Pick<MapVariationForProduct, 'id'>): Promise<CreateDownloadUrlResponse> => {
    return new Promise((resolve, reject) => {
      restClient
        .post(
          "/d", { map_variation_id: mapVariation.id, order_id: order.publicId }
        )
        .then(resp => resolve(resp.data))
        .catch(reject)
    })
  }
}