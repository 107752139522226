import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import AppContext from 'AppContext'
import { backgroundImageUrl } from 'models/Creator'
import FollowshipMenu from 'components/FollowshipMenu';
import { Creator, Followship } from 'models';
import { useFollowships } from 'loaders/FollowshipLoader';
import { FollowshipActionType } from 'reducers/useFollowshipsReducer';
import { Paths } from 'routing'

const TRANSITION_OUT = 300

const FollowshipCardContainer = styled('div')(({ theme }) => ({
  width: '49%',
  display: 'flex',
  justifyContent: 'center',
  margin: '0.5% 0.5% 0.5% 0.5%',
  borderRadius: '10px',
  position: 'relative',
  textAlign: 'center',
  backgroundRepeat: 'no-repeat',
  alignContent: 'center',
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  transition: 'transform 50ms',
  cursor: 'pointer',
  '&:active': {
    '& .creatorLabel': {
      color: 'lightgrey'
    },
  },
  [theme.breakpoints.down('sm')]: {
    height: '100px',
    width: '100%',
    margin: '1% 1% 1% 1%',
  },
  [theme.breakpoints.up('sm')]: {
    height: '175px',
    '&:hover': {
      '& .creatorLabel': {
        background: 'rgba(0,0,0,0.8)',
      }
    }
  },
}))

const CreatorLabel = styled('div')({
  color: 'white',
  background: 'rgba(0,0,0,0.7)',
  border: '1px solid #333',
  fontSize: '2em',
  fontWeight: 'bold',
  borderRadius: '100px',
  padding: '10px 25px',
  margin: 'auto',
  userSelect: 'none'
})

const FollowingPopperContainer = styled('div')({
  position: 'absolute',
  bottom: 0,
  right: 0,
  transform: 'translate(-1vh, -1vh)'
})

interface Props {
  followship: Followship,
  creator: Creator
}

const FollowshipCard = ({ followship, creator }: Props) => {
  const { followshipsDispatch } = useFollowships()
  const navigate = useNavigate()
  const { deviceType, restClient } = useContext(AppContext)
  const isPhone = deviceType === 'phone'
  const [isDeleting, setIsDeleting] = React.useState(false)

  const { creatorSlug } = followship
  const bgUrl = backgroundImageUrl(creator)

  const onCreatorClick = (creatorSlug: Followship["creatorSlug"]) => {
    navigate(Paths.creatorPagePath({ slug: creatorSlug }))
  }
  const handleUnfollow = () => {
    restClient.delete(`/users/followships/${followship.id}`).then((resp) => {
      setIsDeleting(true)
      setTimeout(() => {
        const followship = resp.data
        followshipsDispatch({ type: FollowshipActionType.DELETE, id: followship.id })
      }, TRANSITION_OUT)
    })
  }

  return (
    <FollowshipCardContainer
      key={creatorSlug}
      sx={isDeleting ? { backgroundImage: `url(${bgUrl})`, opacity: '0', transition: `${TRANSITION_OUT}ms opacity ease-out` } : { backgroundImage: `url(${bgUrl})` }}
      onClick={() => onCreatorClick(creatorSlug)}
    >
      {creator.labelHeroImage &&  <CreatorLabel
        className="creatorLabel"
        role="heading"
        sx={{ fontSize: creator.name.length >= 18 ? '1.2em !important' : '2em' }}
      >
        {creator.name}
      </CreatorLabel>}
      {!isPhone && <FollowingPopperContainer>
        <FollowshipMenu
          followship={followship}
          creatorName={creator.name}
          creatorId={creator.id}
          handleUnfollow={handleUnfollow}
        />
      </FollowingPopperContainer>}
    </FollowshipCardContainer>
  )
};

export default FollowshipCard;
