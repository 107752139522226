import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Creator } from 'models'
import FollowshipCard from './FollowshipCard';
import NoFollowshipsWarning from './NoFollowshipsWarning';
import { useFollowships } from 'loaders/FollowshipLoader';
import CenteredCircularProgress from 'components/CenteredCircularProgress';
import AppContext from 'AppContext';

const FollowingHeader = styled('div')(({ theme }) => ({
  fontSize: '3em',
  color: 'white',
  margin: '2vh 2vw',
  fontWeight: 'bold',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2em',
  },
}))

const CreatorCardContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  margin: '10px'
})

const Following = () => {
  const [creators, setCreators] = React.useState<Creator[] | null>(null)
  const { followships } = useFollowships();
  const { restClient } = useContext(AppContext);

  React.useEffect(() => {
    restClient.get("/creators").then((resp) => {
      setCreators(resp.data)
    })
  }, []);

  return (
    <React.Fragment>
      <FollowingHeader>Following</FollowingHeader>
      {!followships && <CenteredCircularProgress />}
      {followships && (!followships.length ?
          <NoFollowshipsWarning />
          :
          <CreatorCardContainer>
            {followships.map((followship) => {
              const { creatorSlug } = followship
              const creator = creators && creators.find(c => c.slug === creatorSlug)

              if (!creator) return null;

              return <FollowshipCard key={creatorSlug} followship={followship} creator={creator} />
            })}
          </CreatorCardContainer>
      )}
    </React.Fragment>
  )
}

export default Following;
