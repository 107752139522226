import React, { useContext } from 'react';

import { Product } from "models";
import AppContext from 'AppContext';

export const useMapProductLoader = (battleMapId: string | undefined) => {
  const [product, setProduct] = React.useState<Product>()
  const { restClient } = useContext(AppContext)

  React.useEffect(() => {
    if (!product && battleMapId) {
      restClient.get(`/products/?map_id=${battleMapId}`).then(resp => {
        const rawProduct = resp.data[0]
        const product = {
          id: rawProduct.sku,
          battleMap: rawProduct.battleMap,
          mapVariations: rawProduct.mapVariations,
          name: rawProduct.name,
        }
        setProduct(product)
      })
    }
  }, [product, battleMapId])

  return product
}
