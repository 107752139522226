import { useContext } from 'react'
import { CreateDownloadUrlResponse } from 'providers/PrivateAssetDownloadProvider'
import { BrowseableBattleMap, isDirectDownloadAccessType } from './BrowseableBattleMap'
import AppContext from 'AppContext'

type CreateHostedUrlFn = (bm: BrowseableBattleMap) => Promise<CreateDownloadUrlResponse>

export const useCreateHostedUrl = (): CreateHostedUrlFn => {
    const { restClient } = useContext(AppContext)

    return (bm: BrowseableBattleMap): Promise<CreateDownloadUrlResponse> => {
    if (!isDirectDownloadAccessType(bm)) throw new Error("Wrong access type")

    return new Promise((resolve, reject) => {
        restClient
            .post("/d", { map_id: bm.id })
            .then(resp => resolve(resp.data))
            .catch(reject)
    })
    }
}