import { RestClient } from "./types";
import { buildRestClient } from "./restClient";
import { useEffect, useMemo, useState } from "react";

declare global {
    interface Window {
        AwsWafIntegration?: {
            // Sometimes we see this as a promise first
            fetch?: typeof window.fetch | Promise<typeof window.fetch>
        }
    }
}

const loadAwsWafIntegrationSdk = () => {
    if (document.getElementById('AwsWafIntegration')) return

    const scriptElt = document.createElement('script')
    scriptElt.id = 'AwsWafIntegration'
    scriptElt.async = false
    scriptElt.src = "https://5138efee8084.us-east-1.sdk.awswaf.com/5138efee8084/e13ffb7847c9/challenge.js"
    document.head.appendChild(scriptElt)
}

const checkFetchLoaded = (): boolean => (
    (!!window.AwsWafIntegration) &&
    typeof window.AwsWafIntegration.fetch === 'function'
)

const MaxAwsWafIntegrationLoadTime = 1000

export const useRestClient = (): RestClient | null => {
    const [fetchLoaded, setFetchLoaded] = useState<boolean>(false)
    const [useWindowFetch, setUseWindowFetch] = useState<boolean>(false)
    const startTime = useMemo(() => Date.now(), [])

    if (!fetchLoaded && checkFetchLoaded()) setFetchLoaded(true)
    
    useEffect(() => {
        if (!fetchLoaded) {
            const id = setInterval(() => {
                if (checkFetchLoaded()) {
                    setFetchLoaded(true)
                    clearInterval(id)
                } else if (Date.now() - startTime > MaxAwsWafIntegrationLoadTime) {
                    setUseWindowFetch(true)
                    clearInterval(id)
                }
            }, 10)
        }
    }, [])

    loadAwsWafIntegrationSdk()

    if (!fetchLoaded && !useWindowFetch) return null

    const fetch = useWindowFetch ? window.fetch : window.AwsWafIntegration?.fetch as typeof window.fetch

    return buildRestClient(fetch)
}
