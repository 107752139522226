import React, { useContext } from 'react';

import { AppLoaderContext } from 'AppLoader';
import SignInClickTrigger from 'components/SignInClickTrigger';
import { Event, EventOpts, TrackerContext, MixpanelProperties } from 'Tracker';
import AppContext from 'AppContext';

export interface BookmarkedProps {
  onClick: () => void
}

export interface NotBookmarkedProps {
  onClick: () => void
}

interface Props {
  mapId: string;
  presentation: {
    LoadPending: React.FC,
    Bookmarked: React.FC<BookmarkedProps>,
    NotBookmarked: React.FC<NotBookmarkedProps>,
    LoginNeeded: React.FC,
  },
  showDialogFirst?: boolean
}

const BookmarkToggle = (props: Props) => {
  const { mapId, presentation, showDialogFirst = false, ...rest } = props;
  const {
    bookmarks, bookmarksPending, setBookmarks, session
  } = React.useContext(AppLoaderContext)
  const { mixpanelProperties } = React.useContext(TrackerContext)
  const { restClient } = useContext(AppContext)

  const Presentation = presentation

  const trackEvent = () => {
    const baseMp: MixpanelProperties = { map_id: mapId }
    const opts: EventOpts = {
      mixpanelProperties: {
        ...(mixpanelProperties || {}), ...baseMp
      }
    }
    window.tracker.track(Event.BookmarkToggled, opts);
  }

  const deleteBookmark = () => {
    trackEvent()
    restClient.delete("/bookmarks/" + mapId);
    const newBookmarks = {...bookmarks}
    delete newBookmarks[mapId]
    setBookmarks(newBookmarks)
  }

  const addBookmark = () => {
    trackEvent()
    restClient.put("/bookmarks/" + mapId);
    const newBookmarks = {...bookmarks}
    newBookmarks[mapId] = true
    setBookmarks(newBookmarks)
  }

  if (!session || bookmarksPending) {
    return <Presentation.LoadPending {...rest} />
  } else if (session.user) {
    if (bookmarks[mapId]) {
      return <Presentation.Bookmarked onClick={deleteBookmark} {...rest} />
    } else {
      return <Presentation.NotBookmarked onClick={addBookmark} {...rest} />
    }
  } else {
    return (
      <SignInClickTrigger
        feature="Bookmark map"
        showDialogFirst={showDialogFirst}
      >
        <Presentation.LoginNeeded {...rest} data-testid="bookmark" />
      </SignInClickTrigger>
    );
  }
}

export default BookmarkToggle
