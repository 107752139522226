import { User } from 'models'
import Cookies from 'js-cookie'
import { CookieName } from 'storage'
import { useContext } from 'react';
import AppContext from 'AppContext';

type VisitorCanBePromptedProps = undefined | { user?: User }

export const visitorCanBePromptedToSubscribe = (props?: VisitorCanBePromptedProps): boolean => {
  if (props?.user) {
    return !props.user.promptedToSubscribeToBlogEmails
  } else {
    return !Cookies.get(CookieName.NotLoggedInSubscribeDialog)
  }
}

type NeverPromptProps = {
  signedIn: boolean
}

type NeverPromptPropsAgainFn = (props: NeverPromptProps) => void

export const useNeverPromptVisitorAgain = (): NeverPromptPropsAgainFn => {
  const { restClient } = useContext(AppContext)

  return ({ signedIn }: NeverPromptProps) => {
    if (signedIn) {
        restClient.put(
          "/user", { prompted_to_subscribe_to_blog_emails: true }
        )
    } else {
      Cookies.set(CookieName.NotLoggedInSubscribeDialog, '1', { expires: 3650 })
    }
  }
}

export const pauseVisitorPrompts = (days: number) => {
  Cookies.set(CookieName.NotLoggedInSubscribeDialog, '1', { expires: days })
}
