import isEmpty from 'lodash/isEmpty'

export const apiUrl = (path: string): URL => (
  new URL(process.env.REACT_APP_RAILS_BASE_URL + "/api" + path)
)

export const discordOauthStartUrl = (queryParams?: {}): URL => {
  const result = url("/users/auth/discord/oauth")
  if (!isEmpty(queryParams)) {
    result.search = new URLSearchParams(queryParams).toString()
  }
  return result
}

export const url = (path: string, queryParams?: {}): URL => {
  const result = new URL(path, process.env.REACT_APP_RAILS_BASE_URL)
  const sanitizedQueryParams = {}
  for (const property in queryParams) {
    const value = queryParams[property]
    if (value !== null && typeof value !== 'undefined') {
      sanitizedQueryParams[property] = value
    }
  }
  if (!isEmpty(sanitizedQueryParams)) {
    result.search = new URLSearchParams(sanitizedQueryParams).toString()
  }
  return result
}