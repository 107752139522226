import React, { useState, useEffect, useContext } from 'react';
import { Catalog, Session, User } from 'models'
import useShoppingCartReducer from 'reducers/useShoppingCartReducer';
import { SplitTestParticipations, mixpanelProperties } from 'splitTests'
import { Bookmarks, AppLoaderContext } from './Context'
import AppContext from 'AppContext';

type Props = {
  children: React.ReactNode
  splitTestParticipations: SplitTestParticipations
}

export function AppLoader({ children, splitTestParticipations }:Props) {
  const [catalog, setCatalog] = useState<Catalog>()
  const [session, setSession] = useState<Session>()
  const [bookmarksPending, setBookmarksPending] = useState<boolean>(true);
  const [bookmarks, setBookmarks] = useState<Bookmarks>({});
  const [purchasedMapIds, setPurchasedMapIds] = useState<string[]>([]);
  const [_shoppingCart, shoppingCartDispatch] = useShoppingCartReducer();
  const { restClient } = useContext(AppContext)

  const refreshSession = () => (
    new Promise<Session>((resolve, reject) => {
      restClient
        .get("/users/session")
        .then(resp => {
          let newSession: Session
          if (typeof resp.data === 'object') {
            const user: User = resp.data
            newSession = { user }
          } else {
            newSession = {}
          }
          setSession(newSession)
          resolve(newSession)
        })
        .catch(() => reject(session))
    })
  )

  useEffect(() => {
    refreshSession()
    restClient.get("/catalog").then(resp => setCatalog(resp.data))
  }, []);

  useEffect(() => {
    if (session?.user) {
      restClient.get("/bookmarks").then((resp) => {
        const bms = {} as Bookmarks
        resp.data.forEach((bookmark) => {
          bms[bookmark.mapEsId] = true
        })
        setBookmarks(bms)
        setBookmarksPending(false);
      })
      restClient.get("/purchased_map_ids").then((resp) => setPurchasedMapIds(resp.data))
    } else {
      setBookmarksPending(false);
    }
  }, [session])

  useEffect(() => {
    if (session) {
      window.tracker.setSession(session)
      if (session.user) {
        const userProperties = mixpanelProperties(splitTestParticipations);
        window.tracker.setUserProperties(userProperties)
      }
    }
  }, [session])

  const value = {
    bookmarks,
    bookmarksPending,
    catalog,
    setBookmarks,
    purchasedMapIds,
    session,
    refreshSession,
    shoppingCartDispatch
  }
  return (
    <AppLoaderContext.Provider value={value}>
      {children}
    </AppLoaderContext.Provider>
  )
}
