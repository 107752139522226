import Cookies from 'js-cookie'
import { CookieName } from 'storage'
import { apiUrl } from '../urls'

type FetchRequestArguments = {
  url: string,
  options: RequestInit,
}

export type FetchRequestArgumentsProps = {
  method: string,
  path: string,
  data?: any,
}

export const fetchRequestArguments = (props: FetchRequestArgumentsProps): FetchRequestArguments => {
  const { path, data, method } = props
  const url = apiUrl(path).toString()
  const headers = {
    'Content-Type': 'application/json',
  } as { [key: string]: string }
  if (Cookies.get(CookieName.CsrfToken)) {
    headers['X-CSRF-Token'] = Cookies.get(CookieName.CsrfToken) as string
  }
  const options = {
    method,
    credentials: 'include' as RequestCredentials,
    headers,
    body: JSON.stringify(data),
  }
  return { url, options }
}
        
