import React, { useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { FlashMessageContext } from 'providers/FlashMessageProvider';
import { setErrorMessageAction } from 'reducers/useFlashMessageReducer';
import AppContext from 'AppContext';

function CreatorOnboardRedirect() {
  const { token } = useParams()
  const navigate = useNavigate();
  const { restClient } = useContext(AppContext)

  const { flashMessageDispatch } = React.useContext(FlashMessageContext)

  React.useEffect(() => {
    if (token) {
      restClient
        .post('/stripe/account_links', {t: token})
        .then((resp) => {
          const accountLink = resp.data
          window.location.replace(accountLink.url)
        })
        .catch((resp) => {
          const { message } = resp.response.data
          if (message) {
            flashMessageDispatch(setErrorMessageAction({ message }))
          }
          navigate("/")
        })
    }
  }, [token, flashMessageDispatch, navigate]);

  return null
}

export default CreatorOnboardRedirect;
