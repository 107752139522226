import camelcaseKeys from 'camelcase-keys'
import * as Sentry from "@sentry/react";

type FetchResponse = {
  status: number,
  text: () => Promise<string>,
}

export const captureBotControlMessages = (status: number): void => {
  if (status === 202) {
    Sentry.captureMessage("Unhandled challenge response from AWS WAF Bot Control")
  } else if (status === 405) {
    Sentry.captureMessage("Unhandled CAPTCHA response from AWS WAF Bot Control")
  }
}

export const parseResponseTextToJson = (fetchResponse: FetchResponse): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetchResponse.text()
      .then(text => {
        const trimmed = text.trim()
        if (trimmed.length > 0) {
          const json = JSON.parse(trimmed)
          resolve(camelcaseKeys(json, { deep: true }))
        } else {
          resolve(null)
        }
      })
    })
}
