import React from 'react';
import { MapVariationCard as DownloadPageMapVariationCard } from 'components/mapDownloadPage'
import { useCreateDownloadUrl } from 'models/MapVariationForProduct'
import { MapVariationForProduct } from 'models'
import { PrivateAssetDownloadContext } from 'providers/PrivateAssetDownloadProvider'
import { Event } from 'Tracker'

type Props = {
  mapVariation: MapVariationForProduct
  zoomIn: () => void
}

const MapVariationCard = (props: Props) => {
  const { mapVariation, zoomIn } = props
  const { downloadPrivateAsset } = React.useContext(PrivateAssetDownloadContext)
  const createDownloadUrl = useCreateDownloadUrl()

  const download = () => {
    downloadPrivateAsset(
        () => createDownloadUrl(mapVariation),
        Event.PatreonRewardDownloaded,
        mapVariation.battleMapId
    )
  }

  return(
    <DownloadPageMapVariationCard
      download={download}
      mapVariation={mapVariation}
      zoomIn={zoomIn}
    />
  )
}

export default MapVariationCard
