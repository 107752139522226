import React from 'react';
import { Event, EventOpts, MixpanelProperties } from 'Tracker'
import { BattleMap } from 'models'
import { apiUrl } from 'server';
import { openUrlInNewWindow } from 'window'

type OpenExternalBattleMapFn = (battleMap: Pick<BattleMap, 'id'>, mixpanelProperties?: MixpanelProperties) => void

type ExternalMapsContextValue = {
  openExternalBattleMap: OpenExternalBattleMapFn
  trackExternalMapPageOpened: (battleMap: Pick<BattleMap, 'id'>, mixpanelProperties?: MixpanelProperties) => Promise<void>
}

export const ExternalMapsContext = React.createContext<ExternalMapsContextValue>({
  openExternalBattleMap: (battleMap: Pick<BattleMap, 'id'>, mixpanelProperties?: MixpanelProperties) => {},
  trackExternalMapPageOpened: (battleMap: Pick<BattleMap, 'id'>, mixpanelProperties?: MixpanelProperties) => Promise.resolve()
})

const trackExternalMapPageOpened = (battleMap: Pick<BattleMap, 'id'>, mixpanelProperties: MixpanelProperties = {}) => {
  const opts: EventOpts = {
    mixpanelProperties: {
      ...{ map_id: battleMap.id },
      ...(mixpanelProperties || {}),
    }
  }
  return window.tracker.track(Event.ExternalMapPageOpened, opts);
}

const openClickThroughUrlInNewWindow = (battleMap: Pick<BattleMap, 'id'>) => (
  openUrlInNewWindow(apiUrl("/c/" + battleMap.id).toString())
)

const openExternalBattleMap = (battleMap: Pick<BattleMap, 'id'>, mixpanelProperties: MixpanelProperties = {}) => {
  trackExternalMapPageOpened(battleMap, mixpanelProperties)
  openClickThroughUrlInNewWindow(battleMap)
}

type Props = {
  children: React.ReactNode
}

const ExternalMapsProvider = ({ children }: Props) => (
  <ExternalMapsContext.Provider value={{ openExternalBattleMap, trackExternalMapPageOpened }}>
    {children}
  </ExternalMapsContext.Provider>
)

export default ExternalMapsProvider
