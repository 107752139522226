import React, { useContext } from 'react'
import { Creator } from 'models';
import { useParams } from "react-router-dom";
import AppContext from 'AppContext';

type CreatorLoaderContextValue = {
  creator?: Creator | null
  // When creator is:
  //   Creator: Found and returned from API
  //   undefined: Still loading from API
  //   null: API has returned not found
}

export const CreatorLoaderContext = React.createContext<CreatorLoaderContextValue | undefined>(undefined)

type Props = {
  children: JSX.Element
}

// Assumes you have a route with `creatorId` param
export const CreatorLoader = ({ children }: Props) => {
  const { creatorId } = useParams<{ creatorId?: string }>();
  const [creator, setCreator] = React.useState<Creator | null>()
  const { restClient } = useContext(AppContext)

  React.useEffect(() => {
    restClient
      .get(`/creators/${creatorId}`)
      .then(resp => setCreator(resp.data))
      .catch(resp => setCreator(null))
  }, []);

  const contextValue = { creator }

  return (
    <CreatorLoaderContext.Provider value={contextValue}>
      {children}
    </CreatorLoaderContext.Provider>
  )
}

export const useCreator = () => {
  const creatorContext = React.useContext(CreatorLoaderContext)

  if (creatorContext === undefined) {
    throw new Error("useCreator must be used within a CreatorLoader")
  }

  return creatorContext
}
