import React, { useContext } from 'react';
import { Avatar, ListItemIcon, MenuItem } from '@mui/material'
import { AppLoaderContext } from 'AppLoader'
import { User } from 'models'
import { Routes } from 'routing/deprecated'
import { MenuControl, MenuControlAnchor, Menu, MenuItemLink } from 'layouts/TopAppBarBase'
import { Bookmark, Map, PersonAdd, Publish, Inventory, Logout, Redeem } from '@mui/icons-material'
import AppContext from 'AppContext';

const SignedIn = ({ user }:{ user: User }) => {
  const { purchasedMapIds } = useContext(AppLoaderContext)
  const { restClient } = useContext(AppContext)

  const signOut = () => {
    restClient.delete("/users/session").then(() => {
      window.tracker.reset()
      window.location.reload()
    })
  }

  return (
    <MenuControl>
      <MenuControlAnchor>
        <Avatar
          alt={user.firstName}
          src={user.avatarUrl}
          sx={{ width: '30px', height: '30px' }}
          data-testid="nav-signed-in"
        />
      </MenuControlAnchor>
      <Menu originHorizontal="right">
        {user.subscription && (
          <MenuItemLink href={Routes.subscriptionPath()} icon={<Map />}>
            Lost Atlas {user.subscription.subscriptionTier.name}
          </MenuItemLink>
        )}
        {purchasedMapIds.length > 0 && (
          <MenuItemLink icon={<Inventory />} href={Routes.purchasedMapsPath()}>
            Purchased maps
          </MenuItemLink>
        )}
        {user.patreonBackCatalogCreatorIds.length === 0 ? null : <MenuItemLink href={Routes.patreonRewardsPath()} icon={<Redeem />}>Patreon rewards</MenuItemLink>}
        <MenuItemLink href={Routes.bookmarksPagePath()} icon={<Bookmark />}>Bookmarks</MenuItemLink>
        <MenuItemLink href={Routes.followingPagePath()} icon={<PersonAdd />}>Following</MenuItemLink>
        {user.administeredCreatorId && <MenuItemLink href={"/dashboard"} icon={<Publish />}>Dashboard</MenuItemLink>}
        <MenuItem onClick={signOut}>
          <ListItemIcon><Logout /></ListItemIcon>
          Sign out
        </MenuItem>
      </Menu>
    </MenuControl>
  )
}

export default SignedIn;
