import React, { useContext } from 'react'
import { setShoppingCartAction, ShoppingCartAction } from 'reducers/useShoppingCartReducer'
import { Event, MixpanelProperties } from 'Tracker'
import { Routes } from 'routing/deprecated'
import { Product } from 'models'
import AppContext from 'AppContext';

type AddToCartProps = {
  product: Pick<Product, 'mapVariations' | 'battleMap' | 'id'>
  navigate: (path: string) => void
  shoppingCartDispatch: React.Dispatch<ShoppingCartAction>
  mixpanelProperties?: MixpanelProperties
}

export type AddToCartFn = (props: AddToCartProps) => void

export const useAddToCart = (): AddToCartFn => {
    const { restClient } = useContext(AppContext)

    return (props: AddToCartProps) => {
        const { product, navigate, shoppingCartDispatch, mixpanelProperties = {} } = props
        const finalMixpanelProperties = {
            ...mixpanelProperties,
            variation_count: product.mapVariations?.length,
            map_id: product.battleMap.id
        }
        window.tracker.track(
            Event.PremiumMapAddedToCart, { mixpanelProperties: finalMixpanelProperties }
        );
        const params = {
            mixpanel_source_component: finalMixpanelProperties.source_component,
            mixpanel_source_page: finalMixpanelProperties.source_page,
            products: [{ id: product.id }]
        }
        restClient
            .post("/shopping_cart/add_item", params)
            .then(resp => {
                shoppingCartDispatch(setShoppingCartAction(resp.data))
                navigate(Routes.checkoutPagePath())
            })
    }
}