import React from 'react';

import { Box, Button } from '@mui/material'

import Layout from 'layouts/Layout';
import { Event } from 'Tracker';
import { withPageWrapper } from 'pages/wrapper';
import { useSubscribeWithStripe } from 'stripe';
import { AppLoaderContext } from 'AppLoader';
import { useNavigate } from 'react-router-dom';
import { subscriptionHasLapsed } from 'models/Subscription';

const ResubscribePage = () => {
  const { session } = React.useContext(AppLoaderContext)
  const navigate = useNavigate()
  const subscribeWithStripe = useSubscribeWithStripe()

  if (!session?.user?.subscription || !subscriptionHasLapsed(session.user.subscription)) {
    navigate('/')
  }

  return (
    <Layout backgroundColor="light" padding="medium">
      <Box
        sx={{
          paddingLeft: { lg: '16px' },
          minHeight: { xs: '1000px', lg: '700px' },
        }}
      >
        <Box sx={{ paddingTop: '10px' }}>
          Looks like your subscription has been cancelled. Would you like to resubscribe?
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
          <Button
            variant="contained"
            size="large"
            onClick={() => subscribeWithStripe(Event.ResubscribePageSubscribeClicked)}
          >
            Subscribe
          </Button>
        </Box>
      </Box>
    </Layout>
  )
}

export default withPageWrapper(
  ResubscribePage,
  Event.ResubscribePageLoaded,
  { signInRequired: true }
) 
