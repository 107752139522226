import React from 'react';
import ErrorBoundary from "./ErrorBoundary";
import { initializeSentry } from 'sentry'
import Tracker from 'Tracker';
import { Router } from 'routing';
import AppContext, { useValue as useAppContextValue } from 'AppContext'
import { subscribe as subscribeToKeyActions } from 'keyActions'
import Providers from 'App/Providers'

// Tracker initialization must be the first thing that happens in the React app
window.tracker = new Tracker({
  mixpanel: {
    token: process.env.REACT_APP_MIXPANEL_TOKEN,
    debug: (process.env.REACT_APP_MIXPANEL_DEBUG === 'true')
  },
});

if (process.env.REACT_APP_SENTRY_DSN) {
  initializeSentry(process.env.REACT_APP_SENTRY_DSN)
}

function App() {
  const appContextValue = useAppContextValue()

  React.useEffect(() => {
    if (new URL(window.location.href).host.match(/^blog\./)) {
      window.location.replace(process.env.REACT_APP_BASE_URL as string)
    }
    subscribeToKeyActions()
  }, [])

  if (!appContextValue) return null

  return (
    <AppContext.Provider value={appContextValue}>
      <ErrorBoundary>
        <Providers splitTestParticipations={appContextValue.splitTestParticipations}>
          <Router />
        </Providers>
      </ErrorBoundary>
    </AppContext.Provider>
  )
}

export default App;
