import { useContext } from 'react'
import AppContext from 'AppContext'
import { MapVariation } from './MapVariation'
import { PrivateAsset } from './PrivateAsset'
import { CreateDownloadUrlResponse } from 'providers/PrivateAssetDownloadProvider'

export interface MapVariationForProduct extends MapVariation {
  id: number
  privateAsset: PrivateAsset
}

type CreateDownloadUrlFn = (mv: MapVariationForProduct) => Promise<CreateDownloadUrlResponse>

export const useCreateDownloadUrl = (): CreateDownloadUrlFn => {
  const { restClient } = useContext(AppContext)

  return (mapVariation: MapVariationForProduct): Promise<CreateDownloadUrlResponse> => (
      new Promise((resolve, reject) => {
        restClient
          .post("/d", { map_variation_id: mapVariation.id })
          .then(resp => resolve(resp.data))
          .catch(reject)
      })
    )
}