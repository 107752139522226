import AppContext from 'AppContext'
import { BrowseableBattleMap } from 'models'
import { useContext } from 'react'
import { Event, EventOpts, MixpanelProperties } from 'Tracker'

type SubscribeWithStripeFn = (event: Event, battleMap?: BrowseableBattleMap, mp?: MixpanelProperties) => void

export const useSubscribeWithStripe = (): SubscribeWithStripeFn => {
  const { restClient } = useContext(AppContext)

  return (event: Event, battleMap?: BrowseableBattleMap, mp?: MixpanelProperties): void => {
    let mixpanelProperties: MixpanelProperties = mp || {}
    if (battleMap) {
      mixpanelProperties = { access_type: battleMap.accessType }
    }
    const eventOpts = { sendImmediately: true, mixpanelProperties } as EventOpts
    window.tracker.track(event, eventOpts)
    const params = {} as any
    if (battleMap) params.map_id = battleMap.id
    restClient.post('/stripe/checkout/sessions', params).then(resp => {
      window.location = resp.data.url
    })
  }
}