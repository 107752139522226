import React, { useContext, useState } from 'react';
import { Event } from 'Tracker'
import { useNeverPromptVisitorAgain, pauseVisitorPrompts } from 'suggestedMaps'
import DialogPresentation from './DialogPresentation'
import AppContext from 'AppContext';

type SubscriptionError = {
  detail: string
}

type Props = {
  dialogOpen: boolean
  setDialogOpen: (dialogOpen: boolean) => void
  setSnackbarOpen: (snackbarOpen: boolean) => void
}

const Dialog = (props: Props) => {
  const { setSnackbarOpen, dialogOpen, setDialogOpen } = props
  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<string | null>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { restClient } = useContext(AppContext)
  const neverPromptVisitorAgain = useNeverPromptVisitorAgain()

  const closeDialogForever = () => {
    setDialogOpen(false)
    neverPromptVisitorAgain({ signedIn: false })
  }

  const decline = () => {
    window.tracker.track(Event.SuggestedMapsSubscribeModalDeclined)
    closeDialogForever()
  }

  const onSubscribeError = (err) => {
    setSubmitting(false)
    if (err.response?.status === 500) {
      // Some kind of server error.  FH will see it via Sentry.  In the
      // meantime, pretend it worked, and remove the opt-in for 7 days.
      setSnackbarOpen(true)
      setDialogOpen(false)
      pauseVisitorPrompts(7)
    } else {
      if (err.response?.data) {
        setEmailError((err.response?.data as SubscriptionError).detail)
      }
    }
  }

  const onSubscribeSuccess = () => {
    setSubmitting(false)
    window.tracker.track(Event.SuggestedMapsSubscribeModalSubscribed)
    setSnackbarOpen(true)
    closeDialogForever()
  }

  const submit = () => {
    setSubmitting(true)
    restClient
      .post(
        "/email/subscriptions",
        { email, mixpanel_distinct_id: window.tracker.getMixpanelDistinctId() }
      )
      .then(onSubscribeSuccess)
      .catch(onSubscribeError)
  }

  return(
    <DialogPresentation
      open={dialogOpen}
      onClose={decline}
      onChange={(evt) => setEmail(evt.target.value)}
      email={email}
      emailError={emailError}
      submit={submit}
      submitting={submitting}
    />
  )
}

export default Dialog
