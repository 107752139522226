import React, { useEffect, useState } from 'react';
import { SplitTestParticipations, ensureSplitTests } from 'splitTests'
import { DeviceType, useDeviceType } from 'responsive'
import { useRestClient, RestClient } from 'server';
import { CookieName } from 'storage';
import Cookies from 'js-cookie';

export type AppContextValue = {
  deviceType: DeviceType
  firstVisit: boolean
  initialLocationHref: string
  splitTestParticipations: SplitTestParticipations
  restClient: RestClient
}

export const useValue = (): AppContextValue | null => {
  const [splitTestParticipations, setSplitTestParticipations] = React.useState<SplitTestParticipations>()
  const deviceType = useDeviceType()
  const [firstVisit, setFirstVisit] = useState<boolean>()
  const restClient = useRestClient()

  useEffect(() => {
    // The first visit condition depends on testing cookies, so make sure to
    // run this *before* anything that might set cookies, such as
    // ensureSplitTests()
    const cookiesAlreadySet = Object.values(CookieName).some(cookieName => {
      return (cookieName !== CookieName.CsrfToken) && Cookies.get(cookieName)
    })
    setFirstVisit(!cookiesAlreadySet)
    setSplitTestParticipations(ensureSplitTests())
  }, [])

  if (!deviceType || typeof firstVisit === 'undefined' || !splitTestParticipations || !restClient) {
    return null
  }

  return {
    deviceType,
    firstVisit,
    splitTestParticipations,
    initialLocationHref: window.location.href,
    restClient
  }
}

const defaultRestClient = {
  get: () => Promise.reject(new Error('restClient not initialized')),
  post: () => Promise.reject(new Error('restClient not initialized')),
  put: () => Promise.reject(new Error('restClient not initialized')),
  delete: () => Promise.reject(new Error('restClient not initialized'))
}

export const defaultValue = {
  deviceType: 'desktop' as DeviceType,
  firstVisit: true,
  initialLocationHref: 'http://example.com/',
  splitTestParticipations: new SplitTestParticipations(),
  restClient: defaultRestClient
}

// For global state that doesn't need any server-loading to be provided to the
// rest of the app. (Server-dependent state is handled in AppLoader.)
const AppContext = React.createContext<AppContextValue>(defaultValue)

export default AppContext
