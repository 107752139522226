import { BattleMap } from '../BattleMap'
import { MapVariation } from '../MapVariation'
import { Product } from '../Product'
import { AccessType } from '../AccessType'

// BattleMap as it appears in Search or Explore, but not as it appears in
// shopping carts or orders
export interface BrowseableBattleMap extends BattleMap {
  accessType: AccessType
  externalUrl: string | null
  product?: Product;
  variations: MapVariation[]
}

export type NewBrowseableBattleMap = Omit<BrowseableBattleMap, 'id'>

export const isDirectDownloadAccessType = (bm: BrowseableBattleMap): boolean => (
  bm.accessType === AccessType.Hosted
)

export const isFreeExternalAccessType = (bm: BrowseableBattleMap): boolean => (
  bm.accessType === AccessType.External
)

export const isPremiumAccessType = (bm: BrowseableBattleMap): boolean => (
  bm.accessType === AccessType.Premium
)
