import { fetchRequestArguments, FetchRequestArgumentsProps } from './request'
import { captureBotControlMessages, parseResponseTextToJson } from './response'
import { OuterConfig, RestClient, ValidateStatusFn } from './types'

type RequestProps = FetchRequestArgumentsProps & {
  fetch: typeof window.fetch,
  validateStatus?: ValidateStatusFn,
}

// Mimic the Response object from ApiClient to ease transition
type Response = {
  data: any,
  status: number,
}

// Mimic the default behavior of Axios to only validate status codes 2XX. In the future we'll want to provide options to move away from this, but this will simplify transition.
const defaultValidateStatus = (status: number): boolean => (
  status >= 200 && status < 300
)

const request = (props: RequestProps): Promise<Response> => {
  const { fetch, validateStatus = defaultValidateStatus, ...rest } = props
  const { url, options } = fetchRequestArguments(rest)
  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then(fetchResponse => {
        const status = fetchResponse.status
        captureBotControlMessages(status)
        if (validateStatus(status)) {
          return parseResponseTextToJson(fetchResponse)
            .then(data => resolve({ data, status }))
        } else {
          // This non-standard rejection is to mimic the behavior of Axios
          /* eslint-disable prefer-promise-reject-errors */
          return parseResponseTextToJson(fetchResponse)
            .then(data => {
              // This non-standard rejection is to mimic the behavior of Axios
              /* eslint-disable prefer-promise-reject-errors */
              reject({ status, response: { status, data } })
            })
        }
      })
  })
}

export const buildRestClient = (fetch: typeof window.fetch): RestClient => ({
  get: (path: string, config?: OuterConfig): Promise<any> => request(
    { fetch, method: 'GET', path, ...config }
  ),

  post: (path: string, data?: any): Promise<any> => request(
    { fetch, method: 'POST', path, data }
  ),

  put: (path: string, data?: any): Promise<any> => request(
    { fetch, method: 'PUT', path, data }
  ),

  delete: (path: string): Promise<any> => request(
    { fetch, method: 'DELETE', path }
  )
})
