import React from 'react'
import { Box, Button } from '@mui/material'
import { OAuthParams, url } from 'server';

type Props = OAuthParams & {
  onClick?: () => void
  children?: React.ReactNode
  urlSearchParams?: {}
}

const SignInWithPatreonButton = (props: Props) => {
  const { destination, onClick, urlSearchParams, children, mapId, command } = props

  const oauthUrl = url(
    '/users/auth/patreon/oauth',
    { ...(urlSearchParams || {}), destination, map_id: mapId, command }
  )

  return (
    <Button
      variant="outlined"
      size="large"
      onClick={() => {
        if (onClick) { onClick() }
        window.location.replace(oauthUrl)
      }}
      startIcon={
        <Box
          component="img"
          src="/images/patreon-icon.png"
          sx={{ height: '26px' }}
        />
      }
    >
      {children || "Sign in with Patreon"}
    </Button>
  )
}

export default SignInWithPatreonButton
